<template>
  <div class="page">
    <div class="details-box">
      <div class="title-box">
        <div class="title-top">
          <span>{{ demand.title }}</span>
          <span class="salary">{{ demand.remuneration }}</span>
        </div>
        <div class="title-bottom">
          <span>
            <van-icon name="location-o" /><span class="title-tip">{{
              demand.workingPlaceName
            }}</span>
          </span>
          <!-- <span style="margin-left: 15px">
            <van-icon name="coupon-o" /><span class="salary" >经验两年以上</span>
          </span> -->
          <span style="margin-left: 15px">
            <van-icon name="underway-o" /><span class="salary"
              >工期{{ demand.projectDuration }} 天</span
            >
          </span>
        </div>
      </div>

      <div class="progress">
<!--        <div class="progress-van">-->
<!--          <h5>进度</h5>-->
<!--          <van-progress :percentage="50" />-->
<!--        </div>-->
        <div class="card">
          <div class="boss-photo">
            <img :src="demand.createdUserAvatar" alt="" />
          </div>
          <div class="boss-info">
            <div class="con-top">
              <div class="boss-name">
                {{ demand.createdUserName }}
                 <span>[{{ demand.status=='REVIEW'?'待审核':demand.status=='SUCCESS'?'审核成功':demand.status=='FAIL'?'失败':'完结' }}]</span>
              </div>
            </div>
            <div class="release">发布于: {{ demand.createdDate }}</div>
          </div>
        </div>
      </div>
      <!--项目描述-->
      <div class="project">
        <div class="describe">
          <div class="tip">项目描述</div>
          <div class="work-type">
            所需工种:
            <span v-for="(item, index) in demand.workerSkill" :key="index">
              <span>{{ item }}</span>
            </span>
          </div>
          <div class="treatment">
            <p v-for="(item, i) in demand.workerInfos" :key="i">
              <van-icon name="info-o" color="#7A9DE5" />:
              {{ item.workType }} 招{{ item.peopleQty }}人,{{
                item.remuneration
              }}
            </p>
          </div>
          <div class="first" v-text="demand.content"></div>
        </div>
      </div>
      <!--工作地址-->
      <div class="work-place">
        <div class="tip">工作地址</div>
        <p v-text="demand.workingPlaceName"></p>
        <!-- <div class="map"></div> -->
      </div>

      <!--安全提示-->
      <div class="safety-tip">
        <div class="tip">
          <span>申请人列表</span>
          <div class="con-list">
            <div class="con-item">
              <div class="user-name">田小强</div>
              <div class="phone"><a href="tel:18453170418">18453170418</a></div>
              <div class="create-date">2021-10-10 12:00:00</div>
            </div>
          </div>
        </div>

        <div class="map"></div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-btn">
        <van-button round type="info" v-if="demand.status=='REVIEW'||demand.status=='FAIL'" plain style="width: 100px" @click="go('/demand-modify/'+id)" >编辑</van-button>
        <van-button round type="info" v-if="demand.status=='SUCCESS'" plain style="width: 100px;margin-left: 30px" @click="finesh" >完结</van-button>
      </div>
    </div>
  </div>
</template>


<script>
import { get, getPhone } from "../../api/demand/index";
import { Dialog, Toast, Notify } from "vant";
import {getDicts,getDictsMap} from "../../api/dict";
import { getTicket } from "../../api/auth";
import wx from "weixin-js-sdk";
import * as demandApi from "../../api/demand";
export default {
  props: ["id"],
  data() {
    return {
      integral: {
        完结需求: 0
      },
      mask: false,
      tel: undefined,
      demand: {
        photo: require("@/assets/images/qizhongji.png"),
        recruitmentTitle: "高新汉裕金谷招水电工8名",
        describe:
          "要求:干活实在,有实力的人,项目稳定,资金没有问题,包吃包住,地点是在济南高新汉裕金谷,具体事宜请与项目经理王老板电话联系.",
        workPlace: "济南高新",
        days: "6天",
        date: "2021.11.4",
        salary: "300元/日",
      },
      pid: undefined,
    };
  },
  created(e) {
    this.pid = this.$route.query.pid;
    if (this.pid) {
      //todo 发送请求道后台记录邀请人
    }
  },
  mounted() {
    this.loadData();
    this.loadDictData();
    console.info("mounted id ",this.id)
  },
  methods: {
    async loadDictData() {
      this.integral = await getDictsMap({ name: "积分类型" })
    },
    async loadData() {
      this.demand = await get(this.id);
      // this.checkPhone();
    },
    finesh(){
      let _this = this;
      Dialog.confirm({
        title: "确认完成",
        message: "点击确定，确认此需求已完结，确认完结奖励"+_this.integral.完结需求+"积分",
      })
      .then(() => {
        this.finishMe();
      })
      .catch(() => {
        // on cancel
      });
    },
    async finishMe(){
      let _this = this;
      await demandApi.finish(this.demand.id);
      this.demand.status="FINISH"
      Dialog.confirm({
        title: "操作成功",
        message: "恭喜你获得"+_this.integral.完结需求+"积分奖励",
      }).then(() => {}).catch(() => {});
    },
    async share() {
      let _this = this;
      let r = await getTicket();
      let ticket = r.data;
      wx.config({
        // debug: true, // 开启调试模式
        appId: ticket.appId, // 必填，公众号的唯一标识
        timestamp: ticket.timestamp, // 必填，生成签名的时间戳
        nonceStr: ticket.nonceStr, // 必填，生成签名的随机串
        signature: ticket.signature, // 必填，签名
        jsApiList: [
          "updateAppMessageShareData", //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
          "updateTimelineShareData", //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
          "onMenuShareAppMessage", //旧的接口，即将废弃
        ], // 必填，需要使用的JS接口列表
      });
      //通过ready接口处理成功验证
      wx.ready(function () {
        _this.wxShareTimeline();
        // this.wxShareAppMessage();

        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });
    },
    wxShareTimeline() {
      // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
      wx.onMenuShareAppMessage({
        title: "世界那么大，我想去看看-微信test", // 分享标题
        desc: "世界那么大，我想去看看-微信test", // 分享描述
        link: location.href.split("#")[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: "http://www.baidu.com/FpEhdOqBzM8EzgFz3ULByxatSacH", // 分享图标
        success: () => {},
      });
    },
    async getPhone() {
      Dialog.confirm({
        title: "联系方式",
        message: "本条信息获取联系方式需要扣除10积分",
      })
        .then(() => {
          this.checkPhoneApi();
        })
        .catch(() => {
          // on cancel
        });
    },
    async checkPhone() {
      //检查是否已经获取过该联系方式
      let r = await demandApi.getPhone(this.demand.id);
      if (r) {
        this.tel = "tel:" + r;
      }
      console.info("checkPhone", this.tel, r);
    },

    async checkPhoneApi() {
      //
      let _this = this;
      let r = await demandApi.getPhone(this.demand.id);
      console.info("checkPhoneApi", r);
      if (r) {
        this.tel = "tel:" + r;
      }
      Dialog.confirm({
        title: "提示",
        message: "点击确定拨打电话：" + _this.tel,
        confirmButtonColor: "#09BB07",
      })
        .then(() => {
          // on confirm
          this.$refs.telPhone.click();
        })
        .catch(() => {
          // on cancel
        });
    },
    go(url){
      this.$router.push(url)
    }
  },
};
</script>

<style lang="less" scoped>
.con-item {
  display: flex;
  justify-content: space-between;
  color: #747577;
  padding: 10px 1px;
  a:link {
    color: #1989fa;
  }
}
.mask {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.2);
}
.maskImg {
  position: fixed;
  top: 6px;
  right: 26px;
  z-index: 100000;
  //width: 507upx;
}
.maskImg img {
  width: 80px;
}
.share-txt{
  color: #fff;
  text-align: center;
  width: 100%;
  margin-top: 60px;
  margin-right: 25px;
  .title{
    font-size: 22px;
  }
}


.page {
  background: #fafafa;
}
h5{
  font-size: 1.1em;
  margin-block-start: 0.9em;
  margin-block-end: 1em;
}
.van-icon{
  top: 2px;
}
.details-box {
  min-height: calc(100vh - 90px);
  .title-box {
    background: #ffffff;
    padding: 10px;

    .title-top {
      font-size: 18px;
      font-weight: 600;

      .salary {
        float: right;
        color: #f38003;
      }
    }

    .title-bottom {
      padding: 5px 0;
      color: #747577;
      font-size: 14px;
    }
  }

  .progress {
    margin-top: 5px;
    background: #ffffff;
    height: 75px;
    padding: 1px 10px;

    .progress-van {
      border-bottom: 1px #f7f7f7 solid;
      height: 60px;
    }
    .card {
      display: flex;
      justify-content: space-between;
      .boss-photo {
        width: 20%;
        padding: 10px 0 10px 10px;
        img {
          width: 45px;
          height: 45px;
        }
      }
      .boss-info {
        width: 80%;
        padding: 10px 10px 10px 0;
        span {
          color: #c3c3c3;
          font-size: 14px;
        }
        .release {
          margin-top: 5px;
          color: #747577;
        }
      }
    }
  }

  .project {
    background: #ffffff;
    /*border: 1px #F38003 solid;*/
    height: 220px;
    padding: 0 10px;
    .describe {
      border-bottom: 1px #f7f7f7 solid;
      .tip {
        font-weight: 600;
        font-size: 1.1em;
        margin: 5px 0;
        padding-top: 5px;
      }
      .work-type {
        color: #3c3c3c;
        font-size: 14px;
        margin-bottom: 10px;
        span {
          padding: 3px;
          background: #f0f5ff;
          color: #6e8fd1;
        }
      }
      .treatment {
        font-size: 14px;
        color: #3c3c3c;
      }
      .first {
        border-bottom: 1px #f7f7f7 solid;
        background: #fafafa;
        color: #3c3c3c;
        font-size: 14px;
        padding: 5px;
        min-height: 140px;
      }
    }
  }
  .work-place {
    padding: 0 10px;
    .tip {
      font-weight: 600;
      font-size: 1.1em;
      margin: 5px 0;
      padding-top: 5px;
    }
    .map {
      height: 160px;
      border: 1px solid #f38003;
    }
  }
  .safety-tip {
    border-bottom: 1px #f7f7f7 solid;
    margin-top: 10px;
    margin-bottom: 80px;
    .tip {
      font-weight: 600;
      font-size: 1.1em;
      margin: 5px 10px;
      padding-top: 5px;
      p {
        font-weight: 500;
        font-size: 14px;
        color: #6e6e6e;
        a {
          color: #1083fc;
        }
      }
    }
  }
}

.footer {
  background: #ffffff;
  padding: 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .footer-btn {
    display: flex;
    justify-content: center;
  }
}
</style>
